@import '../../../utils/variables.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
  margin-top: 12px !important;
}

.label {
  text-align: end;
  font-size: 18px;
  line-height: 21px;
  color: #1e1c20;
}

@media screen and (max-width: 992px) {
  .label {
    max-width: 235px;
    text-align: left;
  }
}

@media screen and (max-width: 576px) {
  .label {
    margin-left: 10px;
    max-width: 155px;
    font-size: 12px;
    line-height: 14px;
  }
}
