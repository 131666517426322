.close {
  display: block;
  position: relative;
  padding: 0;
  transform-origin: center;
  cursor: pointer;
  background: transparent;
  border: none;
  transition: all 0.2s ease-out;
  outline: none;

  &:focus {
    outline: none;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  &:hover {
    transform: rotate(90deg) scale(1.15);
  }
}
