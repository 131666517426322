.popup-content {
  padding: 0px;
  border: none;
  width: 80%;
}

@media screen and (max-width: 991px) {
  .popup-content {
    width: calc(100% - 20px);
  }
}

[role='tooltip'].popup-content {
  padding: 10px;
  width: auto;
}
