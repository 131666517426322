@import '../../../utils/variables.scss';

.block {
  @extend .calc-block;
  padding: 30px 20px 37px 20px;
}

.subtitle {
  @extend .subtitle;
}

.tnved {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &.min {
    max-width: 310px;
  }

  .subtitle {
    margin-left: 16px;
  }
  .input {
    display: block;
    margin-top: 18px;
  }
  & > div {
    width: 47%;
  }

  .delete {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    color: #000;

    span {
      font-size: 20px;
      font-weight: 500;
      color: $red;
    }
  }
}
.tnved-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $main-dark-color;
  border-radius: 10px;
  padding: 16px;
  margin-top: 40px;

  &.red {
    background-color: $red;
    .subtitle {
      color: #fff;
    }
  }
}

.inputs-wrapper {
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: min(calc(100% / 3 - 10px), 170px);
    margin-bottom: 25px;
  }
  & > div:nth-child(3n + 2) {
    margin-left: max(calc((100% - 3 * min(170px, calc(100% / 3))) / 2), 15px);
    margin-right: max(calc((100% - 3 * min(170px, calc(100% / 3))) / 2), 15px);
  }
}

.input {
  @extend .input;
}

.info-block {
  display: flex;
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
  padding: 15px 17px;
  border-radius: 10px;
  background-color: $main-dark-color;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .value {
    @extend .subtitle;
    margin: 0 5px;
    text-align: center;
    color: $dark-gray;
    flex-grow: 1;
  }
}

.yes-no-block {
  display: flex;
  justify-content: space-between;

  & > * {
    flex-basis: 48%;
  }
}

.description {
  @extend .input;
  text-align: left;
  height: 60px;
  text-transform: none;
  font-weight: 400;

  resize: none;
}

@media screen and (max-width: 576px) {
  .tnved-info {
    margin-top: 20px;

    &.curs {
      margin-top: 40px;
    }
  }

  .inputs-wrapper {
    justify-content: space-between;
    & > div {
      width: 48%;
      margin-bottom: 25px;
    }
    & > div:nth-child(3n + 2) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .yes-no-block {
    flex-direction: column;
    & > * {
      flex-basis: 100%;
    }
  }
}
