@import '../../utils/variables.scss';

.calc-block {
  @extend .calc-block;
}
.inputs-block {
  padding-left: 10px;
  padding-right: 10px;
}

.button {
  display: block;
  height: 60px;
  width: 100%;
  background-color: $yellow;
  box-shadow: 0 0 0px rgb(0 0 0 / 25%);
  border: none;
  color: #000;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 18px;
  line-height: 21px;

  &.calc {
    background-color: $main-dark-color;
  }
  &:disabled {
    background-color: $main-color;
    color: $light-gray;
  }
}

.bill {
  margin-top: 24px !important;

  .calc-block {
    padding: 40px;
    margin-bottom: 0;
    height: auto;
  }
}

.bill-error {
  margin-left: 20px;
  color: $red;
}

.hide {
  opacity: 0.5;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.auction {
  @extend .calc-block;

  .title {
    @extend .subtitle;
  }

  .text {
    @extend .subtitle;
    font-weight: 400;
    text-transform: none;
    margin-top: 8px;
  }

  .col {
    height: 100%;
    display: flex;
    align-items: center;
    & > * {
      flex-grow: 1;
    }
  }
}

@media screen and (max-width: 1200px) {
  .bill {
    .calc-block {
      padding: 40px 20px;
    }
  }
}

@media screen and (max-width: 991px) {
  .calc-block {
    height: auto;
  }
}

@media screen and (max-width: 576px) {
  .inputs-block {
    padding: 20px 0;
  }

  .auction {
    .col {
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }
}
