.wrapper {
  display: grid;
  grid-template-columns: min(310px, 100%) 1fr;
  column-gap: 20px;
  grid-template-areas:
    'imgaes bill'
    'imgaes bill'
    'imgaes email';
}

.images {
  grid-area: imgaes;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bill {
  grid-area: bill;
}

.email {
  width: 100%;
  grid-area: email;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #4f4f4f;
  margin-top: 40px;
}

.icon-block {
  display: flex;
  align-items: center;

  img {
    margin-right: 30px;
  }
  span {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #4f4f4f;
  }
}

.bill {
  display: flex;
  flex-direction: column;
  padding: 7px 15px;
  border-radius: 5px;

  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;

  .row {
    padding: 7px 5px;
    align-items: center;
  }

  .row:nth-child(2n - 1) {
    background-color: #fff;
    border-radius: 5px;
  }
}

@media screen and (max-width: 992px) {
  .wrapper {
    grid-template-areas:
      'imgaes bill'
      'imgaes bill'
      'email email';
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    column-gap: 0;
    grid-template-areas:
      'imgaes imgaes'
      'bill bill'
      'email email';
  }

  .bill {
    .row {
      & > * {
        padding: 0;
      }
    }
  }

  .images {
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
      flex-basis: 48%;
      &:first-child,
      &:last-child {
        flex-basis: 100%;
      }
    }
  }

  .logo {
    flex-basis: 100%;
    & > * {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
  .title {
    margin-top: 45px;
    margin-bottom: 55px;
  }
  .icon-block {
    flex-basis: 48%;
  }

  .currency {
    margin: 30px 0;
  }
}

@media screen and (max-width: 576px) {
  .icon-block {
    flex-basis: 48%;
    flex-direction: column;
    text-align: center;
    img {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
