@import '../../../utils/variables.scss';

.header {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px solid gray;

  .input-wrapper {
    margin: 0 20px;

    input {
      height: 40px;
      border-radius: 5px;
      font-size: 12px;
      color: #6e6893;
      padding: 10px;
      border: 1px solid #6e6893;

      &:focus {
        outline: none;
        border: 1px solid #6e6893;
      }
    }
  }
}

.data {
  position: relative;
  height: calc(100% - 51px);
  overflow-y: auto;

  .row {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #f1f1f1;
    cursor: pointer;

    &:hover {
      background: $main-dark-color;
    }

    .name {
      flex-grow: 1;
      margin: 0 20px;
    }
  }

  &.loading {
    opacity: 0.5;
  }

  .shield {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    &.active {
      display: block;
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    flex-direction: column;
    align-items: flex-start;

    .input {
      margin: 0;
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
}
