@import '../../../utils/variables.scss';

.currency {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $main-dark-color;
  border-radius: 10px;
  padding: 16px;

  &.small {
    padding: 13px;
  }
  &.big {
    margin-top: 40px;
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #7c7c7c;
  }
}

@media screen and (max-width: 576px) {
  .currency {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}
