@import '../../../../utils/variables.scss';

.wrapper {
  margin-bottom: 30px;
}

.title {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  color: $secondary-color;
  margin-bottom: 20px;
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.button {
  display: block;
  flex-basis: 48%;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: $main-dark-color;
  color: $secondary-color;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  height: 40px;

  transition: transform 0.2s ease-out;

  &:hover {
    transform: scale(1.02);
  }
  &:disabled {
    background-color: $yellow;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    transform: scale(1) !important;
    box-shadow: none;
  }
}
