@import '../../utils/variables.scss';

.modal {
  width: 100%;
  font-size: 12px;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    background-color: $main-dark-color;
    padding: 1rem;

    .title {
      font-size: 18px;
      color: #1e1c20;
      font-weight: 500;
    }

    .close {
      display: block;
      position: absolute;
      padding: 0;
      width: 20px;
      height: 20px;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      transform-origin: center;
      cursor: pointer;
      background: transparent;
      border: none;
      transition: all 0.2s ease-out;

      &:hover {
        transform: translateY(-50%) rotate(90deg) scale(1.15);
      }
    }
  }

  .content {
    min-height: 50vh;
    height: 75vh;
    max-height: 75vh;
    width: 100%;
  }

  .actions {
    width: 100%;
    padding: 10px 5px;
    margin: auto;
    text-align: center;
  }
}

.button {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 14px;
  line-height: 16px;
  height: 40px;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.05);
  }

  &.fill {
    height: 40px;
    background-color: $yellow;
    box-shadow: 0 0 0px rgb(0 0 0 / 25%);
    border: none;
    color: #000;
  }
  &.fill:hover {
    box-shadow: 0px 3px 10px 0px rgba(50, 50, 50, 0.25);
  }

  &.stroke {
    height: 30px;
    background-color: transparent;
    border: 1px solid #6e6893;
    color: #6e6893;
  }
}

@media screen and (max-width: 991px) {
  .modal {
    .header {
      .title {
        font-size: 16px;
      }
    }
  }
}
