@import '../../../utils/variables.scss';

.email-title {
  margin-bottom: 15px;
  color: #4f4f4f;
  font-weight: 500;
  text-align: center;
}
.bitrix-title {
  @extend .email-title;

  margin-top: 25px;
}
.input-block {
  display: flex;
  justify-content: space-between;

  input {
    display: block;
    flex-grow: 1;

    max-width: 100%;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    padding-left: 10px;
    border: 1px solid transparent;

    &:focus {
      outline: none;
      border: 1px solid #6e6893;
    }
  }

  button {
    display: block;
    color: #fff;
    background-color: #33164e;
    width: 125px;
    cursor: pointer;
    padding: 12px 16px;
    border-radius: 5px;
    border: none;
    font-size: 14px;
    line-height: 16px;

    transition: transform 0.3s ease-out;
    &:hover {
      transform: scale(1.03);
    }

    &[disabled] {
      background-color: gray;
      color: #000;
      cursor: auto;
      background-color: #cecece;

      &:hover {
        transform: none;
      }
    }

    & > div {
      //loader fix
      margin-left: 0;
    }
  }
}

.pdf-block {
  margin: 20px 0 0 0;

  a {
    display: block;
    width: 100%;
    background-color: $yellow;
    box-shadow: 0 0 0px rgb(0 0 0 / 25%);
    border: none;
    border-radius: 5px;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #33164e;
    text-decoration: none;

    transition: transform 0.3s ease-out;
    &:hover {
      color: #33164e;
      transform: scale(1.03);
    }
    &.disabled {
      background-color: $main-dark-color;
      color: $light-gray;
      cursor: auto;
      pointer-events: none;
    }
  }
}

.row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 50px;
  & > * {
    flex-basis: 26%;
  }
  & > *:nth-child(2) {
    flex-basis: 40%;
  }
}

@media screen and (max-width: 768px) {
  .row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
    & > * {
      flex-basis: 48% !important;
    }
    & > *:last-child,
    & > *:first-child {
      flex-basis: 100% !important;
    }
  }
}
