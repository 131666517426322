@import '../../../../utils/variables.scss';

.wrapper {
  width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: auto;
}

.row {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-top: 1px solid #f1f1f1;
  cursor: pointer;

  &:hover {
    background: $main-dark-color;
  }

  &.active {
    background: $secondary-color;
    color: white;
    cursor: auto;
  }

  & > div:first-child {
    margin-right: 15px;
    flex-basis: 20%;
    font-weight: 500;
    font-size: 14px;
  }
  & > div:last-child {
    flex-basis: 80%;
  }
}

.info-block {
  display: flex;
  align-items: center;
  min-height: 60px;
  box-sizing: border-box;
  padding: 15px 17px;
  border-radius: 10px;
  background-color: $main-dark-color;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .value {
    @extend .subtitle;
    margin: 0 5px;
    text-align: center;
    color: $dark-gray;
    flex-grow: 1;
  }
}

@media screen and (max-width: 767px) {
  .row {
    flex-direction: column;
  }
}
