$main-color: #eff2fa;
$main-dark-color: #e1e8f9;
$secondary-color: #6e6893;
$light-gray: #a4a4a4;
$dark-gray: #7c7c7c;
$red: #e44b4b;
$yellow: #ffe500;

.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $secondary-color;
}

%calc-block {
  @media screen and (max-width: 576px) {
    padding: 15px !important;
  }
}
.calc-block {
  @extend %calc-block;
  height: calc(100% - 24px);
  background: $main-color;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 24px;
}

.input {
  height: 40px;
  width: 100%;
  border-radius: 5px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid transparent;
  text-align: center;
  background-color: #fff;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $secondary-color;

  &:focus {
    outline: none;
    border: 1px solid $secondary-color;
  }
  &:disabled {
    background-color: $main-dark-color;
  }

  &.error {
    border-color: $red;
  }
}
