@import '../../../utils/variables.scss';

.calc-block {
  @extend .calc-block;
}

.select-wrapper {
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.title {
  @extend .subtitle;
  display: block;
  margin-bottom: 9px;
}

.input {
  @extend .input;

  text-transform: none;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}
