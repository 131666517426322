@import '../../../../utils/variables.scss';

.title {
  @extend .subtitle;
  text-transform: none;
  text-align: center;
}

.input {
  @extend .input;
}

.column {
  height: 76px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
